import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import queryString from 'query-string';
import CommonLayout, { SubDirectorySection } from '../templates/CommonLayout';
import Title from '../components/Title';
import Logo from '../assets/svg/logo/info.imp.svg';
import { formatDate } from '../Utils';

const Date = styled.h3`
  font-family: var(--font-jp-sans);
  font-size: 1.5em;
  font-weight: 400;
  border-bottom: 1px solid var(--theme-gold);
  padding-bottom: 1em;
`;

const NewsTitle = styled.h2`
  font-weight: 100;
  font-size: x-large;
  padding: 1em 0;
`;

const Thumb = ({ image, title }: { image?: GatsbyTypes.MicrocmsNewsImage; title: string }) => (
  <div
    style={{
      background: 'var(--theme-blue)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    {image?.url ? (
      <img src={image.url} style={{ maxWidth: '100%' }} alt={title || '神保町 五木田'} />
    ) : (
      <Logo
        title="神保町 五木田"
        fill="var(--white)"
        style={{ maxHeight: '200px', marginLeft: '-3.4em', padding: '1em 0' }}
      />
    )}
  </div>
);

const Body = styled.p`
  margin: 2em 0 4em 0;
  font-family: var(--font-jp-sans);
  font-size: 1.2em;
  font-weight: 300;
  * {
    line-height: 1.5em;
  }
`;

const NewsDraftPage = () => {
  const [item, setItem] = useState<GatsbyTypes.MicrocmsNews | null>(null);

  const device = useBreakpoint();
  const isSmallViewer = device.m;

  useEffect(() => {
    const { contentId, draftKey } = queryString.parse(window.location.search);
    fetch(`https://jinbocho-gokita.microcms.io/api/v/news/${contentId}?draftKey=${draftKey}`, {
      headers: new Headers({ 'X-MICROCMS-API-KEY': process.env.MICROCMS_API_KEY ?? '' }),
    })
      .then(res => res.json())
      .then(res => {
        setItem(res);
      });
  }, []);

  return (
    <CommonLayout title="お知らせ一覧" article>
      <SubDirectorySection style={{ width: '100%' }}>
        <Title
          label="News"
          ruby="お知らせ"
          align="center"
          style={{ alignSelf: isSmallViewer ? 'center' : 'flex-start' }}
        />
        <article style={{ width: '100%' }}>
          <div style={{ padding: isSmallViewer ? '3em' : '4em 4em 4em 0' }}>
            <Date>{formatDate(item?.publishedAt)}</Date>
            <NewsTitle>{item?.title}</NewsTitle>
            <Thumb image={item?.image} title={item?.title || '最新ニュース'} />
            <Body>
              <span dangerouslySetInnerHTML={{ __html: item?.body || '' }} />
            </Body>
          </div>
        </article>
      </SubDirectorySection>
    </CommonLayout>
  );
};

export default NewsDraftPage;
